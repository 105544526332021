<template>
    <div class="grid grid-cols-12 gap-x-6 gap-y-3">
        <div class="col-span-6">
            <Input :label="$tc('valid_from', 1)" type="date" id="valid_from" v-model="solar_input.valid_from" :error="errors.valid_from" />
        </div>
        <div class="col-span-6">
            <Input :label="$tc('valid_from', 1)" type="date" id="valid_from" v-model="solar_input.valid_till" :error="errors.valid_till" />
        </div>
        <div class="col-start-1 col-span-6">
            <RadioButtonGroup :label="$tc('client_type', 1)" id="client_type" :options="clientOptions" valueProperty="value" displayProperty="display_name" v-model="solar_input.client_type" />
        </div>
        <div class="col-span-6" v-show="solar_input.client_type === 'tenant'">
            <Select :label="$tc('owner', 2)" id="owner_client_id" :options="clients" displayProperty="id" valueProperty="id" v-model="solar_input.owner_client_id" :error="errors.owner_client_id" />
        </div>
        <div class="col-start-1 col-span-6">
            <Select :label="$tc('ean', 1)" id="ean_id" :options="eans" displayProperty="ean_code" valueProperty="id" v-model="solar_input.ean_id" :error="errors.ean_id" />
        </div>
        <div class="col-start-1 col-span-3">
            <Input :label="$tc('total_consumption', 1)" type="number" id="total_consumption" v-model="solar_input.total_consumption" :error="errors.total_consumption" />
        </div>
        <div class="col-span-3">
            <Input :label="$tc('day_consumption', 1)" type="number" id="day_consumption" v-model="solar_input.day_consumption" :error="errors.day_consumption" />
        </div>
        <div class="col-span-3">
            <Input :label="$tc('night_consumption', 1)" type="number" id="night_consumption" v-model="solar_input.night_consumption" :error="errors.night_consumption" />
        </div>
        <div class="col-start-1 col-span-6">
            <Select :label="$tc('location', 1)" id="location_id" :options="locations" displayProperty="display_name" valueProperty="id" v-model="solar_input.location_id" :error="errors.location_id" />
        </div>
        <div class="col-start-1 col-span-6">
            <Select :label="$tc('direction', 1)" id="direction_id" :options="directions" displayProperty="display_name" valueProperty="name" v-model="solar_input.direction_id" :error="errors.direction_id" />
        </div>
        <div class="col-span-6">
            <Input :label="$tc('injection_tariff', 1)" type="text" id="injection_tariff" v-model="solar_input.injection_tariff" :error="errors.injection_tariff" />
        </div>
        <div class="col-span-6">
            <Input :label="$tc('price_index_percentage', 1)" type="text" id="price_index_percentage" v-model="solar_input.price_index_percentage" :error="errors.price_index_percentage" />
        </div>
        <div class="col-start-1 col-span-6">
            <Select :label="$tc('roof_type', 1)" id="roof_type_id" :options="roofTypeOptions" displayProperty="display_name" valueProperty="name" v-model="solar_input.roof_type_id" :error="errors.roof_type_id" />
        </div>
        <div class="col-span-6">
            <Input :label="$tc('channel', 1)" type="text" id="channel" v-model="solar_input.channel" :error="errors.channel" />
        </div>
        <div class="col-start-1 col-span-6">
            <Select :label="$tc('solar_panel', 1)" id="solar_panel_id" :options="solarPanels" displayProperty="display_name" valueProperty="id" v-model="solar_input.solar_panel_id" :error="errors.solar_panel_id" />
        </div>
    </div>
</template>

<script>
    import ClientService from '@/services/ClientService';
    import SolarService from '@/services/SolarService';

    export default {
        data() {
            return {
                solar_input: {
                    valid_from: '2022-01-01',
                    valid_till: '2022-12-31',
                    client_id: this.$route.params.client_id,
                    client_type: 'owner',
                    owner_client_id: null,
                    ean_id: null,
                    location_id: null,
                    direction_id: 'south',
                    injection_tariff: '50',
                    price_index_percentage: 2,
                    roof_type_id: null,
                    channel: null,
                    solar_panel_id: null,
                    total_consumption: null,
                    day_consumption: null,
                    night_consumption: null,
                },
                clientOptions: [
                    { display_name: 'Owner', value: 'owner' },
                    { display_name: 'Tenant', value: 'tenant' },
                ],
                roofTypeOptions: [
                    { display_name: 'Inclined', value: 'inclined' },
                    { display_name: 'Flat', value: 'flat' },
                    { display_name: 'Ground setup', value: 'ground' },
                ],
                locations: [],
                solarPanels: [
                    { display_name: '450 Wp', id: 1 },
                    { display_name: '425 Wp', id: 2 },
                    { display_name: '400 Wp', id: 3 },
                    { display_name: '380 Wp', id: 4 },
                    { display_name: '350 Wp', id: 5 },
                ],
                directions: [],
                clients: [],
                eans: [],
                errors: {},
            };
        },
        async created() {
            await this.getClients();
            await this.getEans();
            await this.getLocations();
            await this.getDirections();
        },
        methods: {
            async getClients() {
                const { data: clientResponse } = await ClientService.getClients();
                this.clients = clientResponse.data;
            },
            async getEans() {
                const eanResponse = await ClientService.getEans(this.$route.params.client_id);
                this.eans = eanResponse.data.eans;
            },
            async getLocations() {
                const locationResponse = await SolarService.getLocations();
                this.locations = locationResponse.data.locations;
            },
            async getDirections() {
                const directionResponse = await SolarService.getDirections();
                this.directions = directionResponse.data.directions;
            },
            async getEanAmrConsumptions() {
                const directionResponse = await SolarService.getDirections();
                this.directions = directionResponse.data.directions;
            },
        },
        computed: {},
    };
</script>
