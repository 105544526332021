import apiClient from '@/utils/apiClient';

export default {
    getLocations() {
        return apiClient.get('/locations');
    },
    getDirections() {
        return apiClient.get('/directions');
    },
};
